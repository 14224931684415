<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='apply-box'>

          <div class='apply-form'>
            <div class='apply-user'>
              <ul>
                <li><span class='cell-label'>用户头像：</span>
                  <span v-if='userInfo.avatar'>
                     <el-avatar :size='avatarSize' shape='circle' :src='userInfo.avatar' alt='用户头像'></el-avatar>
                </span>
                </li>
                <li><span class='cell-label'>用户昵称：</span><span>{{ userInfo.nickname }}</span></li>
                <li><span class='cell-label'>所属部门：</span>
                  <span v-for='(dept) in userInfo.depts' :key='dept.dpt_id'>
                    <el-tooltip :content='`${dept.name}(${dept.dpt_id})`' placement='bottom' effect='light'>
                      <span style='margin-right: 5px'>{{ dept.name || '' }}</span>
                    </el-tooltip>
                  </span>
                </li>
                <li><span class='cell-label'>当前角色：</span><span>{{ userInfo.role_name || '' }}</span></li>
                <li><span class='cell-label'>上级领导：</span>
                  <el-checkbox-group v-model='formData.leaders' @change='handleCheckedChange'>
                    <el-checkbox v-for='leader in leaders' :label='leader' :key='leader.id'>
                      {{ leader.name }}『{{ leader.dept_name || '暂无部位' }} - {{ leader.position || '暂无职位' }}』
                    </el-checkbox>
                  </el-checkbox-group>
                  <!--                  <span v-for='(leader) in leaders' :key='leader.id' style='margin-right: 5px'>-->
                  <!--                    {{ leader.name || '' }} 『{{ leader.dept_name || '' }}』-->
                  <!--                    <span v-if='leader.is_leave=="Y"' style='color: red'>（已离职）</span>-->
                  <!--                  </span>-->
                </li>
              </ul>
            </div>
            <div>
              <el-button type='text' style='font-size: 1.2em' @click='getList' icon='el-icon-refresh'
                         :loading='loading'>
                可申请的角色：
              </el-button>
              <div class='role-list'>
                <el-tree :data='options' :props='defaultProps'
                         show-checkbox
                         :expand-on-click-node='false'
                         :filter-node-method='filterNode'
                         ref='roleTree'
                         node-key='id'
                         :default-expand-all='true'
                         @check-change='handleCheckChange'
                         @node-click='handleNodeClick'></el-tree>
              </div>
            </div>
            <div class='apply-btn'>
              <el-button round type='primary' size='medium' @click='submit()'>角色申请（{{ formData.selectRole.length }}）
              </el-button>
            </div>
          </div>
        </div>

        <div>
          <el-divider content-position='left'>我的申请记录：</el-divider>
          <div>

          </div>
        </div>

      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplyRole',
  components: { PageHeaderLayout },
  computed: {
    ...mapGetters(['userPermissions', 'roles', 'userInfo'])
  },
  data() {
    return {
      loading: false,
      //我的上级可申请的角色
      roleList: [],
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'display_name'
      },
      options: [],
      leaders: [],//我的上级领导
      formData: {
        selectRole: [],
        leaders: []
      },
      avatarSize: 'large'//"large", "medium", "small"
    }
  },
  methods: {
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data[this.defaultProps.label].indexOf(value) !== -1
    },
    // 节点选中状态发生变化时的回调
    // eslint-disable-next-line no-unused-vars
    handleCheckChange(data, checked, indeterminate) {
      // console.log(data, checked, indeterminate);
      this.formData.selectRole = this.$refs.roleTree.getCheckedNodes(false, false)
    },
    // 节点单击事件
    // eslint-disable-next-line no-unused-vars
    handleNodeClick(data) {
      // this.$emit('handleSelect', data)
      this.selectNode(data.id)

    },
    // 获取节点的选中状态
    isNodeChecked(nodeId) {
      const checkedNodes = this.$refs.roleTree.getCheckedNodes()
      return checkedNodes.some(node => node.id === nodeId)
    },
    //选择节点
    selectNode(nodeId) {
      let isChecked = this.isNodeChecked(nodeId)
      this.$refs.roleTree.setChecked(nodeId, !isChecked)
    },
    // 获取可申请角色列表
    async getList() {
      this.loading = true
      this.options = this.roleList

      let { leaders, roleList } = await this.$api.getMyApplyRoleList()
      this.options = roleList
      this.leaders = leaders

      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    submit() {
      if (this.formData.leaders.length === 0) {
        this.$notify.warning('请先勾选申请上级领导！！！')
        return false
      }

      if (this.formData.selectRole.length === 0) {
        this.$notify.warning('请先勾选申请的角色！！！')
        return false
      }

      //提交申请
      // this.$notify.success(JSON.stringify(this.selectRole)) 『{{ leader.dept_name || '' }}』
      let leaderNames = this.formData.leaders.map((i) => `${i.name}『${i.dept_name || ''}』`)
      let roleNames = this.formData.selectRole.map((i) => i.display_name)
      let applyMsg = `此操作将向【${leaderNames.join('、')}】申请系统角色：【${roleNames.join('、')}】，确认提交申请？`
      this.$confirm(applyMsg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发起请求
        this.handleCommitApply(this.formData)
      }).catch(() => {
        this.$notify.info('已取消')
      })

    },
    async handleCommitApply() {
      //commitApply
      let data = await this.$api.postCommitApply(this.formData.selectRole, this.formData.leaders)
      if (data) {
        this.msgSuccess('已提交申请，请耐心等待审批结果！！！')
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleCheckedChange(value) {
      // console.log(value)
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
.apply-box {
  text-align: center;
  width: 100%;
  height: 100%;
  /*border: #e97ca1 1px dashed;*/
}

.apply-user {
  padding: 10px 20px;
}

.apply-user li {
  display: flex;
}

.apply-user li > .cell-label {
  margin-bottom: 10px;
  font-weight: bolder;
  margin-right: 10px;
}

.apply-form {
  padding: 10px 20px 40px;
  border: #e97ca1 1px dashed;
  min-width: 400px;
  width: 600px;
  height: 100%;
  margin: auto;
  text-align: left;
  border-radius: 5px;
}

.apply-btn {
  /*border: #e97ca1 1px dashed;*/
  display: inline-block;
  text-align: center;
  padding: 10px;
  margin-left: 10%;
  margin-top: 10%;
}
</style>
